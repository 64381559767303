import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";

export default {
  namespaced: true,
  state: {
    scrapes: {},
    websites: {}
  },
  getters: {
    getScrapes: state => state.scrapes,
    getWebsites: state => state.websites
  },
  mutations: {
    setScrapes(state, payload) {
      state.scrapes = payload;
    },
    setWebsites(state, payload) {
      state.websites = payload
    }
  },
  actions: {
    save({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return ApiService.post(`site-scraper`, payload)
          .then(({ data }) => {
            resolve(data);
            return data;
          })
          .catch(({ response }) => {
            commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
      });
    },
    getScrapes({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        ApiService.get(`my-scrapes`,`?${queryString}`)
          .then(response => {
            commit("setScrapes", response.data);
            resolve(response);
          });
      });
    },
    getScrape({commit}, data) {
      return new Promise((resolve, reject) => {
        ApiService.get(`scrape/${data.id}?${data.queryString}`).then(response => {
          commit('setWebsites', response.data)
          resolve(response)
        })
      })
    }
  }
};