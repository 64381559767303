import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";

export default {
  namespaced: true,
  state: {
    records: { data: [] },
    industries: [],
    selectedBusiness: [],
    search: {
      name: "",
      company: "",
      title_full: "",
      title_code_1_desc: "",
      business_category: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      email: "",
      employees_count: ""
    },
    total: 0
  },
  getters: {
    getRecords: state => state.records,
    getIndustries: state => state.industries,
    getSearchValues: state => state.search,
    getSelectedBusiness: state => state.selectedBusiness,
    getTotal: state => state.total
  },
  mutations: {
    setRecords(state, payload) {
      state.records = payload.data;
      state.total = payload.total;
    },
    setSearchProperty(state, payload) {
      state.search[payload.field] = payload.value;
    },
    setIndustries(state, payload) {
      state.industries = payload;
    },
    selectBusiness(state, payload) {
      if (payload.checked) {
        state.selectedBusiness.push(payload.id);
      } else {
        state.selectedBusiness = state.selectedBusiness.filter(item => item !== payload.id);
      }
    },
  },
  actions: {
    search({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        ApiService.get(`search-businesses?${queryString}`)
          .then(({ data }) => {
            commit("setRecords", data);
            resolve(data);
          })
          .catch(({ response }) => {
            commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
      });
    },
    getSuggestions({ commit }, query) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/api/industry-suggestion?industry=${query}`).then(response => {
          commit("setIndustries", response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    setSearchProperty({ commit }, payload) {
      commit("setSearchProperty", payload);
    },
    selectBusiness({ commit }, payload) {
      commit("selectBusiness", payload);
    },
  }
};