import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";

export default {
  namespaced: true,
  state: {
    records: { data: [] },
    selectedPeople: [],
    total: 0,
    search: {
      full_name: "",
      company_name: "",
      title: "",
      state: "",
      country: "",
      email: ""
    }
  },
  getters: {
    getRecords: state => state.records,
    getTotal: state => state.total,
    getIndustries: state => state.industries,
    getSearchValues: state => state.search,
    getSelectedPeople: state => state.selectedPeople
  },
  mutations: {
    setRecords(state, payload) {
      state.records = payload.data;
      state.total = payload.total;
    },
    setTotal(state, payload) {
      state.total = payload;
    },
    setSearchProperty(state, payload) {
      state.search[payload.field] = payload.value;
    },
    setIndustries(state, payload) {
      state.industries = payload;
    },
    selectPeople(state, payload) {
      if (payload.checked) {
        state.selectedPeople.push(payload.id);
      } else {
        state.selectedPeople = state.selectedPeople.filter(item => item !== payload.id);
      }
    }
  },
  actions: {
    search({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        ApiService.get(`search-people?${queryString}`)
          .then((response) => {
            commit("setRecords", response.data);
            commit("setTotal", response.data.total);
            resolve(response.data);
          })
          .catch(({ response }) => {
            commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
      });
    },
    getSuggestions({ commit }, query) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/api/industry-suggestion?industry=${query}`).then(response => {
          commit("setIndustries", response.data);
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    setSearchProperty({ commit }, payload) {
      commit("setSearchProperty", payload);
    },
    selectPeople({ commit }, payload) {
      commit("selectPeople", payload);
    }
  }
};