import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import Business from "./modules/Business";
import SiteScraper from "./modules/SiteScraper";
import People from "./modules/People";
import ListModule from "@/store/modules/ListModule";
import NotificationModule from "@/store/modules/NotificationModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    Business,
    SiteScraper,
    People,
    ListModule,
    NotificationModule
  },
});

export default store;
